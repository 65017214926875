.hero {
  // height: 647px;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../public/images/hero.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 70%;
}
