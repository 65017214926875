@import url("https://fonts.googleapis.com/css?family=Urbanist");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./hero.scss";
@import "./mapbox.scss";
@import "./range.scss";

*,
html,
body {
  margin: 0;
  padding: 0;
  font-family: "Urbanist";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
