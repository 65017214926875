input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
}

/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-runnable-track {
  background: #61a5c2;
  height: 12px;
  border-radius: 4px;
}

/******** Firefox ********/
input[type="range"]::-moz-range-track {
  background: #61a5c2;
  height: 12px;
  border-radius: 16px;
}

/***** Thumb Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -10px; /* Centers thumb on the track */
  background-image: "/images/bike.svg";
  background-color: #d6e4f5;
  border: 1px solid #89c2d9;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-image: url("../../public/images/bike.svg");
  background-repeat: no-repeat;
  background-position: center;
}
/***** Firefox *****/
input[type="range"]::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 4px; /*Removes default border-radius that FF applies*/
  background-color: #89c2d9;
  height: 32px;
  width: 32px;
}

/***** Focus Styles *****/
/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}
